import React, { Fragment, useContext, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { AppContext } from 'src/state/AppState'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import TextSection from 'src/components/TextSection'
import Grid from 'src/components/Grid'
import CateringForm from 'src/components/CateringForm'
import RestaurantList from 'src/components/RestaurantList'
import EventsList from 'src/components/EventsList'
import ContactMap from 'src/components/ContactMap'
import Modal from 'src/components/Modal'
import ScrollEntrance from 'src/components/ScrollEntrance'
import TextLockup from 'src/components/TextLockup'
import NewsletterSignup from 'src/components/NewsletterSignup'
import { CustomSelect } from 'src/components/Select'
import { util, typography, mq } from 'src/styles'

const propTypes = {
	data: PropTypes.object.isRequired,
}

const EventsHeader = styled(Grid)`
	${ util.responsiveStyles('padding-bottom', 91, 51, 33, 26) }
	padding-top: ${ 100 / 14 }vw;
	${ mq.largeAndUp } {
		padding-top: ${ 100 / 26 }vw;
	}
	h1 {
		margin: 0;
		${ typography.h2 }
		line-height: 1.2em;
	}
`

const PageTemplate = ({ data }) => {
	const site = data.allContentfulSiteSettings && data.allContentfulSiteSettings.edges[0].node
	const page = data.allContentfulPage.edges[0].node
	const restaurants = data.allContentfulRestaurant.edges
	const events = data.allContentfulEvent.edges
	const { sections, pageType } = page
	let hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
	if (sections && sections[0].__typename === 'ContentfulSlideshow') {
		hasAtf = true
	}
	const seo = page.seo
	const isHome = page.slug === '/' || !page.slug

	const { toggleModal } = useContext(AppContext)
	const [pageEvents, setPageEvents] = useState()
	const [currentEventTag, setCurrentEventTag] = useState()

	useEffect(() => {
		if (events && events?.length > 0) {
			events.forEach(event => {
				const tags = event?.node?.tags
				if (tags && tags?.length > 0) {
					const lowerCaseTags = tags.map(tag => (
						tag.toLowerCase()
					))
					event.node.tags = lowerCaseTags.filter((t, index) => {
						return lowerCaseTags.indexOf(t) === index
					})
				}
			})
		}
		setPageEvents(events)
	}, [])

	const renderEventTags = () => {
		let eventTags = ['all']
		if (events && events.length > 0) {
			events.forEach((event, index) => {
				if (event?.node?.tags && event?.node?.tags?.length > 0) {
					const lowerCaseTags = event.node.tags.map(element => {
						return element.toLowerCase()
					})
					eventTags.push(lowerCaseTags)
				}
			})
			eventTags = [].concat.apply([], eventTags)
			let uniqueTags = eventTags.filter((t, index) => {
				return eventTags.indexOf(t) === index
			})
			return uniqueTags
		} else {
			return false
		}
	}

	const tagFilters = renderEventTags().map(tag => { return { label: tag, value: tag } })

	const filterEvents = event => {
		if (event?.target?.value && event?.target?.value !== 'all') {
			const filteredEvents = events.filter(e => e?.node?.tags?.includes(event.target.value))
			if (filteredEvents) {
				setPageEvents(filteredEvents)
			} else {
				setPageEvents(events)
			}
		} else {
			setPageEvents(events)
		}
	}

	return (
		<Fragment>
			<SEO
				title={page.title}
				description={seo && seo.description && seo.description.description}
				siteSettings={site}
				keywords={seo && seo.keywords}
				shareImage={seo && seo.shareImage && 'https:' + seo.shareImage.file.url}
			/>
			<Header
				hasAtf={hasAtf}
				bannerText={site.bannerText}
				bannerColor={site.bannerColor}
				navigation={site.navigation}
				socialLinks={site.socialLinks}
				location={page.slug}
				title={site.title}
				pageType={pageType}
				restaurants={restaurants}
				isHome={isHome}
				firstSection={sections && sections[0]}
				site={site}
			/>

			{pageType === 'contact' && (
				<ContactMap />
			)}

			{pageType === 'restaurants listing' && (
				<RestaurantList
					restaurants={restaurants}
					description={page.pageDescription}
					headline={page.title}
				/>
			)}

			{pageType === 'events listing' && (
				<>
					{page.pageDescription && (
						<TextSection
							theme="darkRed"
							textAlignment="center"
							alignment="center"
							paragraphSize="h3"
							text={page.pageDescription}
						/>
					)}
					<EventsHeader
						small='1 [12] 1'
						medium='1 [6] 2 [4] 1'
						larger='1 [6] 3 [3] 1'
						rowGap='20px'
					>
						<div>
							<h1>Event Calendar</h1>
						</div>
						<div>
							{tagFilters && tagFilters?.length > 0 && (
								<div>
			            <CustomSelect
			              onChange={filterEvents}
			              name='categoryFilter'
			              value={currentEventTag}
			              size='small'
			              placeholder='Filter Events'
			              options={tagFilters}
			            />
								</div>
							)}
						</div>
					</EventsHeader>
					{/*
						If we want calendar view on desktop
						<ResponsiveComponent
							large={<EventsCalendar events={pageEvents} />}
							small={<EventsList events={pageEvents} hasSections={sections && sections.length > 0}/>}
						/>
					*/}
					<EventsList events={pageEvents} hasSections={sections && sections.length > 0}/>
				</>
			)}

			{sections && sections.map((section, index) => {
				const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
				const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
				const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
				const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
				const lastSection = sections.length === index + 1
				return (
					<ComponentRenderer
						prevTheme={prevFullWidth ? false : prevTheme}
						nextTheme={nextFullWidth ? false : nextTheme}
						isFirstSection={index === 0}
						isLastSection={lastSection}
						key={section.id + index}
						item={section}
						index={index}
					/>
				)
			})}
			{page.footer !== 'hide footer' && (
				<Footer {...site} />
			)}

			{isHome && site.homepageModal && site.homepageModal.raw && (
				<Modal id='homepageModal'>
					<div
						css={css`
							text-align: center;
							width: 90vw;
							${ util.responsiveStyles('max-width', 800, 600, 600, 600) }
							${ util.responsiveStyles('padding-top', 80, 70, 50, 40) }
							${ util.responsiveStyles('padding-bottom', 95, 85, 60, 50) }
							${ util.responsiveStyles('padding-left', 60, 60, 40, 30) }
							${ util.responsiveStyles('padding-right', 60, 60, 40, 30) }
						`}
					>
						<TextLockup
							text={site.homepageModal}
							alignment='center'
						/>
						<ScrollEntrance delay={2}>
							<div style={{ paddingTop: 26 }}>
								<NewsletterSignup
									theme='default'
									name='newsletterSignupPrompt'
									formId='63c96430b4f015f30446d0f7'
								/>
							</div>
						</ScrollEntrance>
					</div>
				</Modal>
			)}

			<Modal id='cateringForm'>
				<CateringForm/>
			</Modal>
		</Fragment>
	)
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
	query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
			edges {
				node {
					...SiteSettings
				}
			}
		}
		allContentfulPage(filter: {id: {eq: $id}}) {
			edges {
				node {
					id
					title
					pageDescription {
						raw
					}
					pageType
					slug
					sections {
						...Columns
						...FiftyFifty
						...TextSection
						...WideMedia
						...Slideshow
					}
					seo {
						description {
							description
						}
						keywords
						shareImage {
							file {
								url
							}
						}
					}
					footer
				}
			}
		}
		allContentfulRestaurant(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}, sort: {order: ASC, fields: title}, ) {
			edges {
				node {
					...Restaurant
				}
			}
		}
		allContentfulEvent(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
			edges {
				node {
					...Event
				}
			}
		}
	}
`

export default PageTemplate
