import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { mq, util, colors } from 'src/styles'
import Grid from 'src/components/Grid'
import ThemeSelector from 'src/components/ThemeSelector'
import Button from 'src/components/Button'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

const MapWrapper = styled.div`
	width: 100%;
	position: relative;
`

const Overlay = styled(Grid)`
	${ mq.largeAndUp } {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
`

const MapBox = styled.div`
	height: 70vh;
	min-height: 30vw;
	background: #ccc;
	position: relative;
	z-index: 1;
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	${ mq.largeAndBelow } {
		min-height: 90vw;
		height: 75vh;
	}
	${ mq.mediumAndBelow } {
		height: 75vw;
	}
`

const CardOverlay = styled(ThemeSelector)`
	h5 {
		margin: 0;
	}
	${ util.responsiveStyles('padding-top', 70, 50, 30, 30) }
  ${ util.responsiveStyles('padding-bottom', 80, 60, 40, 40) }
  ${ util.responsiveStyles('padding-left', 70, 50, 36, 28) }
  ${ util.responsiveStyles('padding-right', 70, 50, 36, 28) }
  ${ util.responsiveStyles('max-width', 600, 420, 300, 300) }
  ${ mq.mediumAndBelow } {
  	max-width: 100% !important;
  }
	
`

const ContactMap = ({ className, ...rest }) => {
	const { mapSm, mapMd, mapLg, allContentfulSiteSettings } = useStaticQuery(
		graphql`
			query {
				mapSm: file(relativePath:{eq: "images/hugh-map-sm.png"}) {
					publicURL
				}
				mapMd: file(relativePath:{eq: "images/hugh-map-md.png"}) {
					publicURL
				}
				mapLg: file(relativePath:{eq: "images/hugh-map-lg.png"}) {
					publicURL
				}
				allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
			    edges {
			      node {
			        streetAddress
			        cityStateZip
			        directionsLink
			        visitUsText
			      }
			    }
			  }
			}
		`
	)

	const site = allContentfulSiteSettings && allContentfulSiteSettings.edges[0].node

	return (
		<MapWrapper className={className}>
			<MapBox>
				<ResponsiveComponent
					small={<img src={process.env.GATSBY_HOST + mapSm.publicURL} alt='The Hugh location map image' />}
					medium={<img src={process.env.GATSBY_HOST + mapSm.publicURL} alt='The Hugh location map image' />}
					large={<img src={process.env.GATSBY_HOST + mapLg.publicURL} alt='The Hugh location map image' />}
				/>
			</MapBox>
			<Overlay small='[1]' large='1 [12] 1'>
				<div>
					<CardOverlay setTheme='charcoal'>
						<h5 style={{ color: colors.mainColor, marginBottom: '1em', fontSize: '16px' }}>The Hugh</h5>
						{site.visitUsText && (
							<p className='large' style={{ marginBottom: '.7em', marginTop: '0' }}>
								{site.visitUsText}
							</p>
						)}
						<p>{site.streetAddress}<br/>{site.cityStateZip}</p>
						{site.directionsLink && (
							<Button
								icon='arrow_forward'
								iconPosition='right'
								setTheme='secondary'
								to={site.directionsLink}
								external
								target='_blank'
							>Directions</Button>
						)}
					</CardOverlay>
				</div>
			</Overlay>
		</MapWrapper>
	)
}
export default ContactMap
