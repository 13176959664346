import React from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import dayjs from 'dayjs'
import { GatsbyImage } from 'gatsby-plugin-image'
import { util, typography, colors, mq } from 'src/styles'
import { headerHeightCollapsed } from 'src/components/Header'
import ContentfulRichText from 'src/components/ContentfulRichText'

const EventsWrapper = styled.div`
	${ ({ hasSections }) => !hasSections && util.responsiveStyles('padding-bottom', 91, 51, 33, 26) }
`

const MonthSection = styled(Grid)`
	${ util.responsiveStyles('padding-bottom', 91, 51, 33, 26) }
`

const MonthHeadline = styled.div`
	border-top: 2px solid ${ colors.textColor };
	height: 100%;
	p {
		display: none;
	}
	h5 {
		${ typography.h5 }
		margin: 0;
		${ util.responsiveStyles('padding-top', 24, 20, 18, 12) }
		${ util.responsiveStyles('padding-bottom', 24, 20, 18, 12) }
		position: sticky;
		${ headerHeightCollapsed(0, 'top') }
		${ mq.mediumAndBelow } {
			position: static;
			${ typography.h4 }
		}
	}
	${ mq.mediumAndBelow } {
		position: sticky;
		z-index: 2;
		border-top: none;
		background: ${ colors.bgColor };
		${ headerHeightCollapsed(0, 'top') }
		border-bottom: 2px solid ${ colors.textColor };
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 1px;
		p {
			flex-grow: 0;
			${ typography.h6 }
			color: ${ colors.textColor };
			margin: 0;
			display: block;
		}
	}
`

const EventItem = styled(Grid)`
	border-top: 2px solid ${ colors.textColor };
	${ util.responsiveStyles('padding-top', 24, 20, 18, 16) }
	${ util.responsiveStyles('padding-bottom', 24, 20, 18, 16) }
	${ mq.mediumAndBelow } {
		${ ({ firstItem }) => firstItem ? `
			border-top: none;
		` : `` }
	}
	p {
		max-width: 42em;
		margin: .5em 0 0 0;
		${ mq.largerAndBelow } {
			${ typography.bodySmall }
		}
	}
	${ ({ featured, firstItem }) => featured ? `
		color: ${ colors.bgColor };
		background: ${ colors.textColor };
		${ util.responsiveStyles('padding-right', 24, 20, 18, 16) }
	` : `` }
`

const EventTitle = styled.h6`
	${ typography.h5 }
	margin: 0 0 .5em;
`

const EventDate = styled.div`
	${ typography.h5 }
	margin: 0;
	opacity: .5;
	${ ({ featured }) => featured ? `
		${ util.responsiveStyles('padding-left', 24, 20, 18, 16) }
	` : `` }
`

const EventTime = styled.span`
	${ typography.h6 }
	display: block;
	margin: .8em 0 0 0;
	color: ${ colors.mainColor };
`

const EventsList = ({ className, events, hasSections }) => {
	if (!events || events.length < 1) {
		return false
	}
	events.sort((a, b) => (dayjs(a.node.date).isBefore(dayjs(b.node.date)) ? 1 : -1))

	let months = []

	events.forEach((event, index) => {
		event = event.node
		// console.log(event, index)

		let month = false

		if (index + 1 <= events.length) {
			const filteredEvents = events.filter(thisEvent => dayjs(thisEvent.node.date).format('MMMM YYYY') === dayjs(event.date).format('MMMM YYYY'))
			month = { month: dayjs(event.date).format('MMMM'), year: dayjs(event.date).format('YYYY'), date: dayjs(event.date).format('MMMM YYYY'), events: filteredEvents }
			// console.log(filteredEvents)
			// if (filteredEvents) {
			// 	month = filteredEvents
			// }
		}
		if (month) {
			months.push(month)
		}
	})

	months = months.reduce((acc, current) => {
		const x = acc.find(item => item.date === current.date)
		if (!x) {
			return acc.concat([current])
		} else {
			return acc
		}
	}, [])

	return (
		<EventsWrapper hasSections={hasSections}>
			<Grid small="1 [12] 1">
				{months.map((month, index) => (
					<MonthSection small='[1]' large='[4] [8]' key={month.date}>
						<MonthHeadline><h5>{month.month}</h5><p>{month.year}</p></MonthHeadline>
						<div>
							{month.events.map((event, index) => {
								event = event.node
									return (
										<EventItem small='[3] [15]' medium='[1] [8]' vAlign="top" firstItem={index === 0} key={event.id} featured={event.featured}>
											<EventDate featured={event.featured}>{dayjs(event.date).format('DD')}</EventDate>
											<Grid small='[1]' medium={event.image ? '[10] 1 [5]' : '[8]'} extraLarge={event.image ? '[10] 2 [4]' : '[8]'} vAlign="top">
												<div>
													<EventTitle>{event.title}</EventTitle>
													{event?.descriptionText?.raw && (
														<ContentfulRichText richText={event.descriptionText}/>
													)}
													<EventTime>{dayjs(event.date).format('h:mm A')}</EventTime>
												</div>
												{event.image && (
													<div>
														<GatsbyImage image={event.image.gatsbyImageData} />
													</div>
												)}
											</Grid>
										</EventItem>
									)
								}
							)}
						</div>
					</MonthSection>
				))}
			</Grid>
		</EventsWrapper>
	)
}

export default EventsList
