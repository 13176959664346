import React from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import TextSection from 'src/components/TextSection'
import RestaurantThumbnail from 'src/components/RestaurantThumbnail'
import { mq } from 'src/styles'

const ListWrapper = styled.div`
	// padding-top: ${ 100 / 14 }vw;
  ${ mq.largeAndUp } {
    // padding-top: ${ 100 / 26 }vw;
  }
`

const RestaurantList = ({ className, restaurants, headline, description }) => {
	if (!restaurants || restaurants.length < 1) {
		return false
	}
	return (
		<ListWrapper>
			<TextSection
				headline={headline}
				text={description}
				isFirstSection
				alignment='center'
			/>
			<Grid small="1 [12] 1">
				<Grid
					// small="[12]"
					small="[1] [1]"
					medium="[1] [1]"
					large="[1] [1] [1]"
					larger="[1] [1] [1] [1]"
					extraLarge="[1] [1] [1] [1] [1]"
					colGap={['24px', '20px', '20px']}
					rowGap={['7vw', '5vw', '3vw']}
					vAlign='top'
				>
					{restaurants.map((restaurant, index) => {
						restaurant = restaurant.node
						return (
							<RestaurantThumbnail key={restaurant.id} restaurant={restaurant}/>
						)
					})}
				</Grid>
			</Grid>
		</ListWrapper>
	)
}

export default RestaurantList
